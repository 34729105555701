























































































































































import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormInput,
  BPagination,
  BTable,
} from "bootstrap-vue";
import { AvSkeletonTable } from "@/components/av-skeleton";
import { AvButton, AvExportButton } from "@/components";
import { Component, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import ENUMS from "@/enums";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// Services
import { useUser, useAdministrator } from "@/services";

// Interfaces
import {
  IListUser,
  IResponseList,
} from "@core/services/interfaces/user/IUserService";
import { BvTableFieldArray } from "bootstrap-vue";
import { IResponseActivateInactivateUser } from "@core/services/interfaces/administrator/IAdministratorService";

@Component({
  name: "PendingUsersList",
  components: {
    BRow,
    BCol,
    BCard,
    AvSkeletonTable,
    AvButton,
    AvExportButton,
    BCardText,
    BPagination,
    BFormInput,
    BTable,
    vSelect,
  },
})
export default class PendingUsersList extends Vue {
  // Data
  $ENUMS = ENUMS;
  searchQuery = "";
  perPage: number = 10;
  currentPage: number = 1;
  display: number = 0;
  total: number = 0;
  loading: boolean = true;
  sortBy = "";
  isSortDirDesc = "";
  perPageOptions: Array<number> = [10, 25, 50, 100];
  usersPendingActivateId: string | null = null;
  usersPendingList: Array<IListUser> = [];
  filterOn = [];
  modalConfirmShow: boolean = false;
  loadingActivate: boolean = false;
  $refs = {
    refListTable: {} as any,
  };

  tableColumns: BvTableFieldArray = [
    { key: "Nome", label: "Nome", sortable: true },
    { key: "Email", label: "E-mail" },
    { key: "Perfil", label: "Perfil", sortable: true },
    { key: "actions", label: "Ativar Usuário" },
  ];

  // Computeds
  get dataMeta() {
    const localItemsCount = this.usersPendingList.length;

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to:
        this.perPage * this.currentPage < this.display
          ? this.perPage * this.currentPage
          : this.display,
      of: this.display,
      total: this.total,
    };
  }

  async created() {
    this.refreshData();
  }

  // Methods
  onFiltered(filteredItems: Array<any>) {
    this.display = filteredItems.length;
    this.currentPage = 1;
  }

  async refreshData() {
    this.loading = true;
    useUser
      .requestList({
        userdata: {
          status: false,
        },
      })
      .then((response: IResponseList) => {
        this.usersPendingList = response.data.data;
        this.display = this.usersPendingList.length;
        this.total = this.usersPendingList.length;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Usuários pendentes!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  showConfirmActivateUser(userId: string) {
    this.modalConfirmShow = true;
    this.usersPendingActivateId = userId;
  }

  activateUser() {
    if (this.usersPendingActivateId) {
      this.loadingActivate = true;

      useAdministrator
        .requestActivateInactivateUser(this.usersPendingActivateId)
        .then((response: IResponseActivateInactivateUser) => {
          if (response.status == 200 || response.status == 201) {
            this.refreshData();
            this.usersPendingActivateId = null;
            this.modalConfirmShow = false;
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erro ao tentar ativar usuário!",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loadingActivate = false;
        });
    }
  }

  getPendingUsers() {
    return new Promise((resolve, reject) => {
      try {
        const data = this.$refs.refListTable.filteredItems.map(
          (user: IListUser) => ({
            Nome: user.Nome,
            "E-mail": user.Email,
            Perfil: user.Perfil.join("; "),
          })
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
